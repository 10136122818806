<template>
    <b-col :sm="item.width || 12" :element-id="item.id">
        <b-card class="mb-1 pl-fieldset">
            <h5 :style="item.cssStyle" class="mb-2 over-hover" v-if="item.label">
                <strong>{{ item.label }}</strong>

                <PlatonLink
                    style="right: 8px; z-index: 5"
                    class="small over-target ml-2"
                    tabindex="-1"
                    v-if="isAdmin && isMyProject(item)"
                    :link="`/forms/form_elements/${item.id}?_target=modal&form_name=${formMeta.name}`"
                >
                    <i class="fa fa-cog"></i>
                </PlatonLink>
            </h5>

            <div class="form-row">
                <PlatonFormElement element-type="form_elements" :item="e" :key="e.id" v-for="e in activeChildren" />
            </div>
        </b-card>
    </b-col>
</template>

<script>
import PlatonFormElement from "@Platon/components/form/PlatonFormElement.vue"
import PermissionMixin from "@Platon/mixins/table/PermissionMixin"
import PlatonLink from "@Platon/components/extended/PlatonLink.vue"
import FormDataMixin from "@Platon/components/form/FormDataMixin"

export default {
    name: "PlatonFieldset",
    components: { PlatonLink, PlatonFormElement },

    mixins: [PermissionMixin, FormDataMixin],

    props: {
        item: {}
    },

    data() {
        return {
            disabledElements: []
        }
    },

    computed: {
        /**
         * @return {PlatonFormElement[]}
         */
        children() {
            return this.item.children
        },

        activeChildren() {
            let captured = []

            for (let child of this.children) {
                if (child.isAddon && child.addonParent) {
                    let parent = this.children.find((element) => element.id == child.addonParent)
                    parent.addonElementsLeft ||= []
                    parent.addonElementsRight ||= []

                    if (child.addonPosition == "left") {
                        parent.addonElementsLeft.push(child)
                    } else {
                        parent.addonElementsRight.push(child)
                    }
                }

                if (child.type === "html") {
                    let found = [...child.htmlContent.matchAll(/@\[(\w+)]/g)].map((match) => match[1])

                    captured.push(...found)
                }
            }
            return this.children.filter(
                (child) => !(child.isAddon && child.addonParent) && !captured.includes(child.dataField)
            )
        }
    }
}
</script>
